import { graphql } from 'gatsby'
import React from 'react'
import TaxSheets from '../pagesFiles/taxsheets'

export default function taxdashs({data}) {
    return (
        <TaxSheets questions={data.allFaqJson.nodes[0].taxsheets} />
    )
}

export const query = graphql`
  {
    allFaqJson {
      nodes {
        taxsheets {
          body
          id
          question
        }
      }
    }
  }
`;