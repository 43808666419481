import styled from "styled-components";

export const Title = styled.h3`
  font-weight: 600;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: ${(p) => p.theme.primary};
  text-align: center;
`;
