import React, { useEffect, useState } from "react";
import Button from "../../DesignSystem/Button";
import FormInput from "../../DesignSystem/FormInput";
import Modal from "../../DesignSystem/Modal";

import * as S from "./style";

import { useInput } from "../../hooks/useInput";

export default function ModalTaxSheets(props) {
  const [isVisible, setIsVisible] = useState(false);

  const {
    value: email,
    setValue: setEmail,
    isInvalid: isInvalidEmail,
    bind: bindEmail,
    reset: resetEmail,
  } = useInput("", "email");
  const {
    value: nome,
    isInvalid: isInvalidNome,
    bind: bindNome,
    reset: resetNome,
  } = useInput("");
  const {
    value: tel,
    isInvalid: isInvalidTel,
    bind: bindTel,
    reset: resetTel,
  } = useInput("", "tel");
  const {
    value: empresa,
    isInvalid: isInvalidEmpresa,
    bind: bindEmpresa,
    reset: resetEmpresa,
  } = useInput("");

  const sendForm = () => {
    console.log(nome, email, tel, empresa);
    resetEmail();
    resetEmpresa();
    resetNome();
    resetTel();
  };

  useEffect(() => {
    setEmail(props.email);
  }, [isVisible, props.email, setEmail]);

  const handleDisplayChange = (display) => {
    setIsVisible(display);
  };

  return (
    <Modal onDisplayChange={handleDisplayChange} ref={props.refModal}>
      <S.Title>Começar teste do TaxSheets</S.Title>
      <FormInput
        required
        name="nome"
        label="Nome"
        mt={16}
        placeholder="Nome Sobrenome"
        error={isInvalidNome}
        errorMsg="Campo obrigatório"
        {...bindNome}
      />
      <FormInput
        required
        name="email"
        label="Email"
        type="email"
        mt={16}
        placeholder="nome@email.com"
        error={isInvalidEmail}
        errorMsg="Digite um email válido para continuar"
        {...bindEmail}
      />
      <FormInput
        required
        name="tel"
        type="tel"
        label="Telefone"
        mt={16}
        placeholder=""
        error={isInvalidTel}
        errorMsg="Digite um telefone válido para continuar"
        {...bindTel}
      />
      <FormInput
        required
        name="empresa"
        label="Empresa"
        mt={16}
        placeholder=""
        error={isInvalidEmpresa}
        errorMsg="Campo obrigatório"
        {...bindEmpresa}
      />
      <Button fluid fullWidth center mt={32} onClick={sendForm}>
        Baixar TaxSheets
      </Button>
    </Modal>
  );
}
