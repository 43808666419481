import React, { useRef, useState } from "react";

import { Container } from "../../styles/UI";

import * as S from "../taxdashs/style";

import taxsheetsIllustration from "../../images/taxsheets/taxsheets-hero2.png";
import taxsheetsLogo from "../../images/taxsheets/taxsheets-logo-negative.svg";

import icon1 from "../../images/taxsheets/edit-fill.svg";
import icon2 from "../../images/taxsheets/warning.svg";
import icon3 from "../../images/taxsheets/import_export.svg";

import iconDetalhes1 from "../../images/taxsheets/table_view.svg";
import iconDetalhes2 from "../../images/taxsheets/fast_forward.svg";
import iconDetalhes3 from "../../images/taxsheets/download_done.svg";

import iconDetalhes4 from "../../images/taxsheets/business.svg";
import iconDetalhes5 from "../../images/taxsheets/filter_alt.svg";
import iconDetalhes6 from "../../images/taxdashs/attach_money.svg";

import illustration1 from "../../images/taxsheets/illustrationts1.png";
import illustration2 from "../../images/taxsheets/illustrationts2.png";
import illustration3 from "../../images/taxsheets/illustrationts3.png";

import iconBg2 from "../../images/taxdashs/vectorfat.svg";

import taxsheetsBgLogo from "../../images/taxsheets/taxsheets-bg-logo.svg";

import Layout from "../../components/Layout";
import ProductCard from "../../components/ProductCard";
import QuestionWrapper from "../../components/QuestionWrapper";
import Input from "../../DesignSystem/Input";
import Button from "../../DesignSystem/Button";
import HeroCard from "../../components/HeroCard";
import StyledLink from "../../DesignSystem/StyledLink";
import TestimonialsWrapper from "../../components/Testimonials";
import ArrowNumber from "../../components/ArrowNumber";
import Clientes from "../../components/Clientes";
import ApresentacaoHome from "../home/apresentacao";
import ModalTaxSheets from "../../components/ModalTaxSheets";
import { navigate } from "gatsby-link";
import Seo from "../../components/Seo";
import axios from "axios";

export default function TaxDashs({ questions }) {
  const modalRef1 = useRef();

  const [email, setEmail] = useState("");

  const client = axios.create({
    baseURL: `https://taxcellicenseserver2.azurewebsites.net/`,
    headers: {
      "Content-Type": `application/x-www-form-urlencoded`,
    },
  });

  const handleClick = () => {
    // modalRef1.current.openModal();

    const form = new URLSearchParams();

    form.append("source", "Baixar-Teste-Incompleto");
    form.append("email", email);

    client.post(`/RD_Station.aspx`, form).then((resp) => {
      console.log(resp);
    });

    navigate("/baixar-taxsheets#formulario", { state: { email } });
  };

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setEmail(value);
  };

  return (
    <Layout>
      <Seo title="TaxSheets" />
      <ModalTaxSheets email={email} refModal={modalRef1} />
      <S.Hero>
        <S.Container>
          <div className="cta">
            <img src={taxsheetsLogo} alt="logo TaxDashs" />
            <h2>TaxSheets - Leitor e Editor de SPED</h2>
            <h1>Agilidade e autonomia com arquivos fiscais do SPED</h1>
            <p>
              Transforme arquivos fiscais do SPED em planilhas editáveis do
              Excel e dê um salto na sua produtividade.
            </p>
            <Input
              value={email}
              onChange={handleChange}
              short
              negative
              placeholder="Seu email"
            >
              <Button onClick={handleClick} bg="secondary" color="primary">
                Teste já
              </Button>
            </Input>
          </div>
          <div className="illustration">
            <div className="taxdashs">
              <img src={taxsheetsIllustration} alt="" />
            </div>
            <img src={taxsheetsBgLogo} alt="" className="bg-logo" />
          </div>
        </S.Container>
      </S.Hero>
      <Container>
        <HeroCard>
          <div>
            <img src={icon1} alt="" />
            <p>
              Edite múltiplos arquivos <br />
              de uma só vez
            </p>
          </div>
          <div>
            <img src={icon2} alt="" />
            <p>
              Verifique erros com <br />
              facillidade
            </p>
          </div>
          <div>
            <img src={icon3} alt="" />
            <p>
              Exporte arquivos no <br />
              padrão do SPED
            </p>
          </div>
        </HeroCard>
      </Container>
      <S.VideoSection>
        <Container>
          <h3 className="column-2">
            Fazer do jeito certo não <br />
            precisa ser tão difícil
          </h3>
          <div className="iframe-container column-1">
            <iframe
              src="https://www.youtube.com/embed/6-OzQp3u4oo"
              title="video-taxdashs"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <p className="column-2">
            Com o <b>TaxSheets</b> você reduz drasticamente seus problemas com:
          </p>
          <ul className="column-2">
            <li>Processos manuais e repetitivos</li>
            <li>Mudanças de regras fiscais</li>
            <li>Sistemas ERP pouco flexíveis</li>
            <li>Atrasos, erros e multas</li>
          </ul>
        </Container>
      </S.VideoSection>
      <S.Detalhes>
        <Container>
          <h3>Feito para quem trabalha na linha de frente da área fiscal</h3>
          <div>
            <div className="detalhes">
              <img src={iconDetalhes1} alt="" />
              <img className="iconBg" src={iconBg2} alt="" />
              <h6>Mais fácil</h6>
              <p>
                Edite arquivos do SPED diretamente no Excel, sem depender de
                outras plataformas.
              </p>
            </div>
            <div className="detalhes">
              <img src={iconDetalhes2} alt="" />
              <img className="iconBg" src={iconBg2} alt="" />
              <h6>Mais rápido</h6>
              <p>
                Otimize processos e tenha autonomia para entregar sempre no
                prazo.
              </p>
            </div>
            <div className="detalhes">
              <img src={iconDetalhes3} alt="" />
              <img className="iconBg" src={iconBg2} alt="" />
              <h6>Menos erros</h6>
              <p>
                Antecipe problemas e mantenha-se atualizado aos padrões e
                layouts exigidos pelo fisco.
              </p>
            </div>
          </div>
          <Input
            value={email}
            onChange={handleChange}
            placeholder="Seu email"
            center
            negative
            short
          >
            <Button onClick={handleClick}>Teste já</Button>
          </Input>
          <StyledLink to="/planos" mt={24} arrow color="secondary">
            Conheça nossos planos
          </StyledLink>
        </Container>
      </S.Detalhes>
      <S.Testimonials>
        <Container>
          <div className="cta">
            <h4>Simplifique seu trabalho com arquivos do SPED</h4>
            <p>
              Trabalhe de forma mais segura com o <b>TaxSheets</b> no Excel e
              fique tranquilo para se concentrar no que você faz de melhor.
            </p>
            <Input
              value={email}
              onChange={handleChange}
              placeholder="Seu email"
              short
            >
              <Button onClick={handleClick}>Teste já</Button>
            </Input>
          </div>
          <TestimonialsWrapper
            produto="taxsheets"
            className="cards"
            light
            buttonColor="laranja"
          />
        </Container>
      </S.Testimonials>
      <S.ComoFunciona>
        <Container>
          <h5>
            Como <span>Funciona</span>
          </h5>
          <div className="passo">
            <ArrowNumber>01</ArrowNumber>
            <h6>Importe arquivos do SPED originais diretamente no Excel</h6>
            <img src={illustration1} alt="" />
            <p>
              Importe de forma organizada e rápida para o Excel os registros dos
              arquivos fiscais EFD ICMS-IPI, EFD Contribuições, ECD, ECF, NFe,
              CTe, CFe, DCTF, PER/DCOMP e GIA.
            </p>
          </div>
          <div className="passo reverse">
            <ArrowNumber>02</ArrowNumber>
            <h6>
              Edite, revise e corrija erros
              <br /> de uma só vez
            </h6>
            <img src={illustration2} alt="" />
            <p>
              Utilize filtros, tabelas dinâmicas, fórmulas comparativas e todas
              as outras funcionalidades do Excel para conferir, corrigir e
              validar as informações prestadas no seu arquivo do SPED.
            </p>
          </div>
          <div className="passo">
            <ArrowNumber>03</ArrowNumber>
            <h6>
              Exporte seus arquivos ajustados e prontos para entrega ao fisco
            </h6>
            <img src={illustration3} alt="" />
            <p>
              Após a correção dos erros no Excel, faça a exportação da planilha
              para o arquivo TXT no layout do SPED pronto para a validação final
              do PVA.
            </p>
          </div>
        </Container>
      </S.ComoFunciona>

      <S.Detalhes>
        <Container>
          <h3>O TaxSheets foi feito para:</h3>
          <div>
            <div className="detalhes">
              <img src={iconDetalhes4} alt="" />
              <img className="iconBg" src={iconBg2} alt="" />
              <h6>Escritórios de contabilidade</h6>
              <p>
                Nossa ferramenta reduz significativamente o tempo dos
                profissionais na análise e correção de erros do SPED, além de
                dar qualidade às informações prestadas ao fisco.
              </p>
            </div>
            <div className="detalhes">
              <img src={iconDetalhes5} alt="" />
              <img className="iconBg" src={iconBg2} alt="" />
              <h6>Consultorias</h6>
              <p>
                Identifique de forma precisa contingências e créditos
                tributários de seus clientes. Faça filtros e verifique
                informações do SPED dentro do próprio Excel e apresente
                resultados mais precisos.
              </p>
            </div>
            <div className="detalhes">
              <img src={iconDetalhes6} alt="" />
              <img className="iconBg" src={iconBg2} alt="" />
              <h6>Departamento Fiscal</h6>
              <p>
                Não perca mais tempo na revisão e correção dos arquivos do SPED.
                Utilize o Excel para cruzar as informações com seus controles
                paralelos e corrigir erros. Evite a aplicação de multas por
                erros de preenchimento ou de apuração.
              </p>
            </div>
          </div>
          <Input
            value={email}
            onChange={handleChange}
            placeholder="Seu email"
            center
            negative
            short
          >
            <Button onClick={handleClick}>Teste já</Button>
          </Input>
          <StyledLink to="/planos" mt={24} arrow color="secondary">
            Conheça nossos planos
          </StyledLink>
        </Container>
      </S.Detalhes>
      <S.Clientes>
        <Container>
          <h5 className="column-1">Clientes que confiam na Taxcel</h5>
          <h4 className="column-1">
            Mais de 730 clientes 300 grandes corporações estão transformando sua
            gestão tributária com a Taxcel
          </h4>
          <Clientes />
          <Input
            value={email}
            onChange={handleChange}
            placeholder="Seu email"
            className="column-1"
          >
            <Button onClick={handleClick}>Teste já</Button>
          </Input>
          <StyledLink to="/planos" className="column-1" mt={16} arrow>
            Conheça nossos planos
          </StyledLink>
        </Container>
      </S.Clientes>
      <S.Faq>
        <Container>
          <h5 className="title">Dúvidas frequentes</h5>
          <QuestionWrapper questions={questions} />
          <p className="duvidas">
            Mais dúvidas?{" "}
            <StyledLink
              external
              to="https://taxceladdins.zendesk.com/hc/pt-br"
              arrow
              color="laranja"
            >
              Acesse nosso Portal de Ajuda
            </StyledLink>
          </p>
        </Container>
      </S.Faq>
      <ApresentacaoHome white />
      <S.Solucoes>
        <Container $clip>
          <h5>Conheça nossa solução para Analytics e BI</h5>
          <ProductCard fluid product="taxdashs" />
        </Container>
      </S.Solucoes>
      <S.Final>
        <Container>
          <h5>Inicie hoje sua jornada em direção ao Tax Transformation</h5>
          <Input
            value={email}
            onChange={handleChange}
            placeholder="Seu email"
            center
          >
            <Button onClick={handleClick}>Teste já</Button>
          </Input>
          <StyledLink to="/planos" mt={16} arrow>
            Conheça nossos planos
          </StyledLink>
        </Container>
      </S.Final>
    </Layout>
  );
}
